
// scrollbar styles for drop down

.select2-dropdown {
    width: 100%;
    background: var(--c-bg);
    border: 2px solid var(--c-gray);
    $scrollbar-bg: var(--c-gray-2);
    $scrollbar-color:  var(--c-gray-5);


    max-height: 40vh;
    padding: 0;

    scrollbar-color: $scrollbar-color $scrollbar-bg;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: $scrollbar-bg;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
    }

    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-color;
        outline: none;
    }

    .filter-wrapper{
        padding: var(--margin-m) var(--margin-m) 0 var(--margin-m);
    }


    .options-wrapper {
        overflow: auto;
        max-height: calc(40vh - 70px);
        margin-top: var(--margin-m);
        padding: 0 var(--margin-m);
        scrollbar-color: $scrollbar-color $scrollbar-bg;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: $scrollbar-bg;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $scrollbar-color;
            outline: none;
        }
    }

    &.open {
        border: 2px solid var(--c-lead);
    }
}

.dropdown-option-wrapper {
    position: relative;
    background: var(--c-gray);


}

.select2-dropdown > * {
    cursor: pointer;
}

.filter-wrapper{
    align-items: center;
}

.select2-filter-input {

}

.select2-dropdown-close {
    transform: none;
    transition: transform 200ms ease-in-out;

    &--absolute {
        position: absolute;
        top: var(--margin-s);
        inset-inline-end : var(--margin-s);
        z-index: 5;
        padding: var(--margin-s);
        border: 1px solid red;
    }

    &:hover {
        transform: rotate(180deg);
    }
}



